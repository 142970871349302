import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Wapisi from "../../assets/imgs/ret-icn.png";
import Modal from 'react-bootstrap/Modal';
import EditGrey from "../../assets/imgs/edit-grey.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ModalTickIcon from "../../assets/imgs/TickMark.png";
import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";
function EditReviews() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Manage Reviews</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                <Link to="/Reviews">
                  <img src={Wapisi} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-right-body customer-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="edit-mange-cent position-relative">
                <div className="edit-mnge-heading">
                  <h3>Product Name</h3>
                </div>
                <div className="edit-mnge-star">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <div className="order-list-heading inner-left-head mng-reve-he d-flex align-items-center">
                  <img className="invert-none" src={EditGrey} alt="" />
                  <h4>Reviews</h4>
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Name</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="text" placeholder="Mark Allen" />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Email</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="email" placeholder="mark_allen@gmail.com" />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Review Title</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="text" placeholder="Lorem ipsum dolor sit" />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end h-100">
                  <p>Review Message</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <textarea placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."></textarea>
                </div>
              </div>

              <div className="app-dec-btn">
                <button className="appr" onClick={handleShow}>Approve</button>
                <button className="decl" onClick={handleShow}>Decline</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="DeleteModal" show={show} onHide={handleClose}>
        <div className="tick-modal-parent">
          <div className="modal-close-button text-end"><img onClick={handleClose} src={ModalCancelIcon} alt="" /></div>
          <div className="delete-modal-content-parent">
            <div className="Tick-modal-img"><img src={ModalTickIcon} alt="" /></div>
            <div className="dlte-modal-txt"><h3>Review has been Approved</h3></div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditReviews;
