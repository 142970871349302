import React, { useEffect } from "react";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import DeleteIcon from "../../assets/imgs/dlt-icn.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import PlusIcon from "../../assets/imgs/pls-icn.png";
import PrImg from "../../assets/imgs/hajpakg.jpg";
import PrIconDel from "../../assets/imgs/pr-del.png";
import PrIconEdit from "../../assets/imgs/pr-edit.png";
import Pagi from "../../assets/imgs/pagi.png";
import ModalDeleteIcon from "../../assets/imgs/modal-delete.png";
import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";
import axios from "axios";


function Product() {
  const [show, setShow] = useState(false);
  const [packagesData, setPackagesData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  useEffect(() => {
    axios.get("https://api.flowhrm.site/getAllPackages")
      .then(response => {
        setPackagesData(response.data);
        console.log(response.data.Hotels, "priceee")      })
      .catch(error => {
        console.error("There was an error fetching the package data!", error);
      });

  }, []);

 



  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>All Pakages</h2>
            </div>
            <div className="right-icons-parent">
              {/* <div className="right-icn">
                <Link to="/ProductAdd">
                  <img src={PlusIcon} alt="" />
                </Link>
              </div> */}
              <div className="right-icn">
                <img onClick={handleShow} src={DeleteIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="d-flex justify-content-between">
                <div className="order-list-heading inner-left-head d-flex align-items-center">
                  <img src={BulletedIcon} alt="" />
                  <h4>List</h4>
                </div>
                <div className="main-page-box-inner add-pt-btn">
                  <Link to="/ProductAdd">Add Package</Link>
                </div>
              </div>
              <div className="pr-table-parent">
                <div className="pr-table-inner-parent">
                  <div className="pr-table brdr-right-pr text-center">
                    <input type="checkbox" />
                  </div>
                  <div className="pr-table brdr-right-pr text-center">
                    <p>Images</p>
                  </div>
                  <div className="pr-table brdr-right-pr text-indent-cust">
                    <p>Package Name</p>
                  </div>
                  <div className="pr-table brdr-right-pr text-indent-cust">
                    <p>Price</p>
                  </div>
                  <div className="pr-table text-indent-cust">
                    <p>Action</p>
                  </div>
                </div>
              {packagesData?.map((pkg) => (
        <div key={pkg.id} className="pr-table-inner-parent pr-table-inner-parent-rev">
          <div className="pr-table brdr-right-pr text-center">
            <input type="checkbox" />
          </div>
          <div className="pr-table brdr-right-pr text-center align-items-center">
            <img src={pkg.image} alt="" />
          </div>
          <div className="pr-table brdr-right-pr text-indent-cust">
            <p>{pkg.packageName}</p>
          </div>
          <div className="pr-table brdr-right-pr text-indent-cust">
            <p>
              <span className="norm-price">{pkg?.Hotels.hotelName}</span>
            </p>
          </div>
          <div className="pr-table text-indent-cust">
            <div className="dlt-edit">
              <img
                className="pr-table-del-img"
                onClick={handleShow}
                src={PrIconDel}
                alt=""
              />
              <Link to={`/EditProduct/${pkg.id}`}>
                <img src={PrIconEdit} alt="" />
              </Link>
            </div>
          </div>
        </div>
      ))}
            
            
              </div>
              <div className="d-flex justify-content-between">
                <div className="pagi">
                  <img src={Pagi} alt="" />
                </div>
                <div className="txt-right-pagi">
                  <p>Showing 1 to 10 of 90 (9 Pages)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="DeleteModal" show={show} onHide={handleClose}>
        <div className="delete-modal-parent">
          <div className="modal-close-button text-end"><img onClick={handleClose} src={ModalCancelIcon} alt="" /></div>
          <div className="delete-modal-content-parent">
            <div className="dlt-modal-img"><img src={ModalDeleteIcon} alt="" /></div>
            <div className="dlte-modal-txt"><h3>You are about to delete this package</h3></div>
            <div className="delete-modal-btn-parent">
              <button className="GoBack" onClick={handleClose}>Go Back</button>
              <button className="dlt-any" onClick={handleClose}>Delete Anyway</button>
            </div>
          </div>
          
        </div>
      </Modal>
    </>
  );
}

export default Product;
