import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Wapisi from "../../assets/imgs/ret-icn.png";
import LeftChevi from "../../assets/imgs/left-chev.png";
import EditGrey from "../../assets/imgs/edit-grey.png";
import PlusCirc from "../../assets/imgs/plus-circ.png";
import Editor from "./Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";
import baseUrl, { getFormattedDate } from "../../utils/utils";
import Swal from "sweetalert2";

function EditPackage() {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State to manage loader
  const [data, setData] = useState("");
  const [formValues, setFormValues] = useState({
    packageName: "",
    returnDate: "",
    days: "",
    nights: "",
    from: "",
    airLine: "",
    bagAllowance: "",
    stopOver: "",
    route: "",
    departureTime: "",
    arivalTime: "",
    secondDestination: "",
    description: "",
  });

  const { id } = useParams();
  console.log(id, " iddddd");
  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    setEditorLoaded(true);

    axios
      .get(`${baseUrl}/getPackage/${id}`)
      .then((response) => {
        setFormValues(response.data);
        console.log(formValues, "formValuesss");
      })
      .catch((error) => {
        console.error("There was an error fetching the package data!", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleShowFile = () => {
    alert("your file is selected");
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const packageData = {
      ...formValues,
      availiblity: true,
      deletedAt: null,
      createdBy: 0,
      updatedBy: null,
      deletedBy: null,
      isDeleted: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      Prices: [],
      Hotels: [],
    };

    axios
      .put(`${baseUrl}/updatePackage/${id}`, packageData)
      .then((response) => {
        setIsLoading(false); // Hide loader
        console.log(JSON.stringify(response.data));
        Swal.fire("Success!", "Updated successfully", "success");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false); // Hide loader
        Swal.fire("Opps!", "Something wen wrong", "error");
      });
  };

  console.log({ formValues });

  return (
    <>
      <SideBar />
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Package</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                {/* <Link to="/Product">
                  <img src={Wapisi} alt="" />
                </Link> */}
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="left-chevi">
                <Link to="/Product">
                  <img src={LeftChevi} alt="" />
                </Link>
              </div>
              <div className="order-list-heading inner-left-head pr-add-heading d-flex align-items-center">
                <img className="invert-none" src={EditGrey} alt="" />
                <h4>Edit Package</h4>
              </div>
              <div className="add-pr-row">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="col9inner-add-pr">
                      <div className="input-pr-parent-sup">
                        <div className="Section-AddPack">
                          <h5>Information</h5>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Package Name
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="packageName"
                              value={formValues.packageName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        {/* <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4><span>*</span>Departure date</h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="date"
                              name="departureTime"
                              value={formValues.departureTime}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div> */}
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Return date
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="date"
                              name="returnDate"
                              value={formValues.returnDate}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Number of days
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="number"
                              name="days"
                              value={formValues.days}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Number of nights
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="number"
                              name="nights"
                              value={formValues.nights}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="input-pr-parent-sup">
                        <div className="Section-AddPack">
                          <h5>Flight Details</h5>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Flying from
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="from"
                              value={formValues.from}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Airline
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="airLine"
                              value={formValues.airLine}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Baggage allowance
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="bagAllowance"
                              value={formValues.bagAllowance}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Stopover in
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="stopOver"
                              value={formValues.stopOver}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Departure time
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="datetime-local"
                              name="departureTime"
                              value={getFormattedDate(formValues.departureTime)}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Arrival time
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="datetime-local"
                              name="arivalTime"
                              value={getFormattedDate(formValues.arivalTime)}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Arrival destination
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="secondDestination"
                              value={formValues.secondDestination}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Route
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="route"
                              value={formValues.route}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div className="save-btn-add-pr d-flex justify-content-end">
                      {isLoading ? (
                        <button disabled>Loading....</button>
                      ) : (
                        <button onClick={handleSubmit}>Update</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPackage;
