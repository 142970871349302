import React from "react";
import { Link, useLocation } from "react-router-dom";
import ProfilePic from "../../assets/imgs/logooo.jpg";
import logo from "../../assets/imgs/logo.png";
import dashboardIcon from "../../assets/imgs/dashboardIcon.png";
import BoxIcon from "../../assets/imgs/BoxIcon.png";
import ReportsIcon from "../../assets/imgs/ReportsIcon.png";
import SaleIcon from "../../assets/imgs/SaleIcon.png";
import CustomerIcon from "../../assets/imgs/CustomerIcon.png";
import ShutdownIcon from "../../assets/imgs/ShutdownIcon.png";

export default function SideBar() {
  const location = useLocation();

  const pathName = location.pathname;

  return (
    <>
      <div className="first-side-bar">
        <div className="side-bar-main">
          <div className="side-bar-parent">
            <div className="brn-logo d-flex justify-content-center">
              {/* <Link to="" className="text-center">
                <img src={logo} alt="" />
              </Link> */}
            </div>
            <div className="user-img">
              <img src={ProfilePic} alt="" />
              <h5 className="text-theme">Admin</h5>
            </div>
            <div className="main-sidebar-list">
              <ul className="side-list-flex p-0 w-100">
                <li className="flex-list-li" aria-current="page"></li>
                {/* <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/AdminDashboard"
                    className={
                      pathName == "/AdminDashboard" || pathName == "/AdminDashboard"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={BoxIcon} alt="" />
                    Dashboard
                  </Link>
                </li> */}

                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/Customer"
                    className={
                      pathName == "/Customer" || pathName == "/CustomerEdit"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={CustomerIcon} alt="" />
                    Add Package
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/addPrice"
                    className={
                      pathName == "/addPrice" || pathName == "/CustomerEdit"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={CustomerIcon} alt="" />
                    Add Price
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/addHotels"
                    className={
                      pathName == "/addHotels" || pathName == "/CustomerEdit"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={CustomerIcon} alt="" />
                    Add Hotels
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/Sales"
                    className={
                      pathName == "/Sales" || pathName == "/SalesView"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={SaleIcon} alt="" />
                    Package Detail
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/priceDetail"
                    className={
                      pathName == "/priceDetail" || pathName == "/priceDetail"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={SaleIcon} alt="" />
                    Price Detail
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/hotelDetail"
                    className={
                      pathName == "/hotelDetail" || pathName == "/hotelDetail"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={BoxIcon} alt="" />
                    Hotel Details
                  </Link>
                </li>

                {/* <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/Reviews"
                    className={
                      pathName == "/Reviews" || pathName == "/ReviewsEdit"
                        ? "activebarside"
                        : ""
                    }
                  >
                    <img src={ReportsIcon} alt="" />
                    Manage Reviews
                  </Link>
                </li> */}
              </ul>
            </div>
            <Link
              onClick={() => localStorage.removeItem("isLogin")}
              className="logout-btn start-50 position-absolute"
              to="/"
            >
              <img src={ShutdownIcon} alt="" />
              <span className="mx-1">Logout</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
