import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LeftChevi from "../../assets/imgs/left-chev.png";
import EditGrey from "../../assets/imgs/edit-grey.png";
import PlusCirc from "../../assets/imgs/plus-circ.png";
import Editor from "./Editor";

function AddProduct() {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState("");
  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Package</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                {/* <Link to="">
                  <img src={Wapisi} alt="" />
                </Link> */}
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="left-chevi">
                <Link to="/Product">
                  <img src={LeftChevi} alt="" />
                </Link>
              </div>
              <div className="order-list-heading inner-left-head pr-add-heading d-flex align-items-center">
                <img className="invert-none" src={EditGrey} alt="" />
                <h4>Add Package</h4>
              </div>
              <div className="add-pr-row">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="col9inner-add-pr">
                      <div className="input-pr-parent-sup">
                        <div className="Section-AddPack">
                          <h5>Information</h5>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Package Name
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input type="text" />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Departure date:
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input type="text" />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Return date:
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input type="text" />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Number of days:
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input type="text" />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Number of nights:
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input type="text" />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="input-pr-parent-sup">
                        <div className="input-pr-parent-sup">
                          <div className="Section-AddPack">
                            <h5>Flight Details</h5>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Flying from
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Airline
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Baggage allowance
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Stopover in
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Departure time
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Arrival time
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Arrival destination:
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Second destination
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Route
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="input-pr-parent-sup">
                        <div className="input-pr-parent-sup">
                          <div className="Section-AddPack">
                            <h5>Hotle Information</h5>
                          </div>
                          <div className="input-pr-parent">
                            <div className="text-input-pr d-flex align-items-center justify-content-end">
                              <h4>
                                <span>*</span>Hotle
                              </h4>
                            </div>
                            <div className="input-pr">
                              <input type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {/* <h3>Prices</h3> */}
                      <div className="input-pr-parent-sup">
                        <div className="Section-AddPack">
                          <h5>Prices</h5>
                        </div>
                        <div className="input-pr-parent input-pr-parent-pd">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>Adult Price</h4>
                          </div>
                          <div className="input-pr">
                            <input type="number" placeholder="€" />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>Child Price</h4>
                          </div>
                          <div className="input-pr">
                            <input type="number" placeholder="€" />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>Baby Price</h4>
                          </div>
                          <div className="input-pr">
                            <input type="number" placeholder="€" />
                          </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="Section-AddPack">
                          <h5>Total Package Price</h5>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>Package Price</h4>
                          </div>
                          <div className="input-pr">
                            <input type="number" placeholder="€" />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="input-pr-parent-sup">
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-start justify-content-end">
                            <h4>Description</h4>
                          </div>
                          <div className="input-pr">
                            <Editor
                              name="description"
                              onChange={(data) => {
                                setData(data);
                              }}
                              editorLoaded={editorLoaded}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="save-btn-add-pr d-flex justify-content-end">
                      <button>Save</button>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div className="right-parent-sup">
                      <div class="right-parent">
                        <div class="image-box-parent">
                          <div class="image-box-heading">
                            <h3>Package Banner/Image ( Jpg/Png )</h3>
                          </div>
                          <div class="image-box" onclick="selectImage(this)">
                            <input
                              type="file"
                              class="image-input"
                              id="fileInput"
                              accept="image/*"
                              onChange={handleImageChange}
                            />
                            {selectedImage && (
                              <div>
                                <img
                                  src={selectedImage}
                                  alt="Selected"
                                  width="200"
                                />
                              </div>
                            )}
                            {selectedImage ? (
                              ""
                            ) : (
                              <p>
                                <span>
                                  <img
                                    class="img-plus"
                                    src={PlusCirc}
                                    alt=""
                                    onClick={() => {
                                      document
                                        .getElementById("fileInput")
                                        .click();
                                    }}
                                  />
                                </span>
                                Upload Package Image
                              </p>
                            )}
                          </div>
                        </div>
                        <div class="prdt-upld-btn">
                          <Link>Upload</Link>
                        </div>
                      </div>
                      {/* <div class="right-parent">
                        <div class="image-box-parent">
                          <div class="image-box-heading">
                            <h3>Upload Document ( Pdf )</h3>
                          </div>
                          <div class="image-box" onclick="selectImage(this)">
                            <input
                              type="file"
                              class="image-input"
                              accept="application/pdf"
                              id="PdffileInput"
                              onchange="displayImage(event, this)"
                              onChange={handleFileChange}
                            />
                            {selectedFile && (
                              <div>
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  size="2x"
                                  onClick={handleShowFile}
                                />
                                <label>File</label>
                              </div>
                            )}
                            {selectedFile ? (
                              ""
                            ) : (
                              <p>
                                <span>
                                  <img
                                    class="img-plus"
                                    src={PlusCirc}
                                    alt=""
                                    onClick={() => {
                                      document
                                        .getElementById("PdffileInput")
                                        .click();
                                    }}
                                  />
                                </span>
                                Upload Document
                              </p>
                            )}
                          </div>
                        </div>
                        <div class="prdt-upld-btn">
                          <Link>Upload</Link>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
