// import React, { useState } from "react";
// import Bannervid from "../../assets/imgs/haj.jpg";
// // import LoginLogo from "../../assets/imgs/Loginlogo.png";
// import Envelope from "../../assets/imgs/envelope.png";
// import Lock from "../../assets/imgs/lock.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
// function LoginAdmin() {
//   const [show, setShow] = useState(false);
//   const [password, setPassword] = useState(false);

//   const id = "muslim";
//   const pass = 123;

//   return (
//     <>
//       <section className="login-section">
//         <div className="login-content-parent">
//           <div className="login-box">
//             <div className="login-box-inner">
//               <div className="login-logo text-center">
//                 {/* <img src={LoginLogo} alt="" /> */}
//               </div>
//               <div className="login-head-para text-center">
//                 <h3>Login</h3>
//                 <p>Welcome Admin! Please enter your credentials</p>
//               </div>
//               <div className="input-and-rem-parent">
//                 <div className="input-login position-relative">
//                   <span className="env-icon position-absolute">
//                     <img src={Envelope} alt="" />
//                   </span>
//                   <input type="email" placeholder="Email Address" />
//                 </div>
//                 <div className="input-login position-relative">
//                   <span className="lock-icon position-absolute">
//                     <img src={Lock} alt="" />
//                   </span>
//                   <input
//                     type={show ? "text" : "password"}
//                     placeholder="Password"
//                     onChange={(e) => {
//                       setPassword ? setShow(false) : setShow(true);
//                     }}
//                   />
//                   <FontAwesomeIcon
//                     className="eye-icon"
//                     onClick={() => {
//                       show ? setShow(false) : setShow(true);
//                     }}
//                     icon={show ? faEyeSlash : faEye}
//                   />
//                 </div>
//                 <div className="remem-me">
//                   <input type="checkbox" />
//                   <span>Remember Me</span>
//                 </div>
//               </div>
//               <div className="login-btn">
//                 <Link to="/Product">Login</Link>
//               </div>
//             </div>
//           </div>
//         </div>
//         <img src={Bannervid} className="background-video-login" />
//       </section>
//     </>
//   );
// }

// export default LoginAdmin;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Bannervid from "../../assets/imgs/haj.jpg";
import Envelope from "../../assets/imgs/envelope.png";
import Lock from "../../assets/imgs/lock.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function LoginAdmin() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email == "admin@gmail.com" && password == 123456) {
      localStorage.setItem("isLogin", true);
      // Redirect to the desired page
      navigate("/AdminDashboard");
    } else {
      setError("Invalid email or password");
    }
  };

  return (
    <>
      <section className="login-section">
        <div className="login-content-parent">
          <div className="login-box">
            <div className="login-box-inner">
              <div className="login-head-para text-center">
                <h3>Login</h3>
                <p>Welcome Admin! Please enter your credentials</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="input-and-rem-parent">
                  <div className="input-login position-relative">
                    <span className="env-icon position-absolute">
                      <img src={Envelope} alt="" />
                    </span>
                    <input
                      type="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-login position-relative">
                    <span className="lock-icon position-absolute">
                      <img src={Lock} alt="" />
                    </span>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="eye-icon"
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? faEyeSlash : faEye}
                    />
                  </div>
                </div>
                <div className="login-btn">
                  <button type="submit">Login</button>
                </div>
                {error && <p className="error-message">{error}</p>}
              </form>
            </div>
          </div>
        </div>
        <img src={Bannervid} className="background-video-login" alt="" />
      </section>
    </>
  );
}

export default LoginAdmin;
