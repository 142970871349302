import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Wapisi from "../../assets/imgs/ret-icn.png";
import LeftChevi from "../../assets/imgs/left-chev.png";
import EditGrey from "../../assets/imgs/edit-grey.png";
import PlusCirc from "../../assets/imgs/plus-circ.png";
import Editor from "./Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";
import Swal from "sweetalert2";
import baseUrl from "../../utils/utils";

function AddProduct() {
  const [data, setData] = useState("");
  const [packagesData, setPackagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to manage loader
  const [formValues, setFormValues] = useState({
    packageName: "",
    packageId: "",
    persons: "",
    amountPerPerson: "",
  });

  useEffect(() => {
    axios
      .get(`${baseUrl}/getAllPackages`)
      .then((response) => {
        setPackagesData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the package data!", error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "packageName") {
      const selectedPackage = packagesData.find(
        (pkg) => pkg.packageName === value
      );

      setFormValues({
        ...formValues,
        packageName: selectedPackage ? selectedPackage.packageName : "",
        packageId: selectedPackage ? selectedPackage.id : "",
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  const handleSubmit = () => {
    setIsLoading(true); // Show loader
    axios
      .post(`${baseUrl}/addPrice`, formValues)
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        Swal.fire("Success!", "Price added successfully", "success");
        setIsLoading(false); // Show loader

        // Clear form fields after successful submission
        setFormValues({
          packageId: "",
          packageName: "",
          persons: "",
          amountPerPerson: "",
        });
      })
      .catch((error) => {
        // Handle error
        console.error("Error adding price:", error);
        Swal.fire("Error", `Something went wrong: ${error.message}`, "error");
        setIsLoading(false); // Show loader
      });
  };

  return (
    <>
      <SideBar />
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Price</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                {/* <Link to="/Product">
                  <img src={Wapisi} alt="" />
                </Link> */}
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="left-chevi">
                <Link to="/Product">
                  <img src={LeftChevi} alt="" />
                </Link>
              </div>
              <div className="order-list-heading inner-left-head pr-add-heading d-flex align-items-center">
                <img className="invert-none" src={EditGrey} alt="" />
                <h4>Add Price By Package</h4>
              </div>
              <div className="add-pr-row">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="col9inner-add-pr">
                      <div className="input-pr-parent-sup">
                        <div className="Section-AddPack">
                          <h5>Pricing</h5>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Package Name
                            </h4>
                          </div>
                          <div className="input-pr">
                            <select
                              name="packageName"
                              value={formValues.packageName}
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                {formValues.packageName
                                  ? formValues.packageName
                                  : "Select a package"}
                              </option>
                              {packagesData?.map((pkg) => (
                                <option key={pkg.id} value={pkg.packageName}>
                                  {pkg.packageName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>No Of Persons
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="number"
                              name="persons"
                              value={formValues.persons}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Price Per Person
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="number"
                              name="amountPerPerson"
                              value={formValues.amountPerPerson}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div className="save-btn-add-pr d-flex justify-content-end">
                      {isLoading ? (
                        <button disabled>Loading...</button>
                      ) : (
                        <button onClick={handleSubmit}>Save</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
