
const baseUrl = "https://api.flowhrm.site"; // Replace with your actual base URL
// const baseUrl = "http://localhost:5000"; // Replace with your actual base URL

export const getImage = (imageName) => `${baseUrl}/image/${imageName}`


export const getFormattedDate = (date) => {
  return date ? new Date(date)?.toISOString()?.slice(0, 16) : ""
}

export default baseUrl;
