import React from "react";
import { Link } from "react-router-dom";
import Eyeicn from "../../assets/imgs/eye-icon.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import Hue from "../../assets/imgs/hue.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Pagi from "../../assets/imgs/pagi.png";

function ManageReviews() {
  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Manage Reviews</h2>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="order-list-heading inner-left-head d-flex align-items-center">
                <img src={BulletedIcon} alt="" />
                <h4>Review List</h4>
              </div>
              <div className="grid-manage-parent-sup">
                <div className="grid-manage-parent">
                  <div className="grid-manage-inner">
                    <p>Customer Name</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Product Name</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Review Title</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Rating</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Status</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Action</p>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="yel-circ" src={Hue} alt="" /> Pending
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                    <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="yel-circ" src={Hue} alt="" /> Pending
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                  <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="yel-circ" src={Hue} alt="" /> Pending
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                  <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="red-circ" src={Hue} alt="" /> Declined
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                  <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="green-circ" src={Hue} alt="" />
                      Approved
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                  <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="red-circ" src={Hue} alt="" /> Declined
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                  <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="green-circ" src={Hue} alt="" />
                      Approved
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                  <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>Mark Allen</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">
                    <p>
                      <img className="red-circ" src={Hue} alt="" /> Declined
                    </p>
                  </div>
                  <div className="grid-manage-inner">
                  <Link to="/ReviewsEdit">
                      <img src={Eyeicn} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="pagi mnge-rev-pagi">
                  <img src={Pagi} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageReviews;
