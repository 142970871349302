import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import axios from "axios";
import SideBar from "./SideBar";

import DeleteIcon from "../../assets/imgs/dlt-icn.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import PlusIcon from "../../assets/imgs/pls-icn.png";
import image from "../../assets/imgs/hajpakg.jpg";
import PrIconDel from "../../assets/imgs/pr-del.png";
import PrIconEdit from "../../assets/imgs/pr-edit.png";
import Pagi from "../../assets/imgs/pagi.png";
import ModalDeleteIcon from "../../assets/imgs/modal-delete.png";
import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";

import baseUrl, { getImage } from "../../utils/utils";

function HotelDetails() {
  const [hotels, setHotels] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(null);

  useEffect(() => {
    fetchHotels();
  }, []);

  const fetchHotels = () => {
    axios
      .get(`${baseUrl}/getAllHotels`)
      .then((response) => {
        setHotels(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the hotels data!", error);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}/deleteHotel/${id}`)
      .then(() => {
        alert("Hotel deleted successfully!");
        fetchHotels(); // Refresh the hotels list after deletion
      })
      .catch((error) => {
        console.error("There was an error deleting the hotel!", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedHotelId(id);
    setShow(true);
  };

  return (
    <>
      <SideBar />
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Hotel Detail</h2>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="d-flex justify-content-between">
              </div>
              <div className="pr-table-parent">
                <div className="pr-table-inner-parent">
                  <div className="pr-table brdr-right-pr text-center">
                    <input type="checkbox" />
                  </div>
                  <div className="pr-table brdr-right-pr text-center">
                    <p>Images</p>
                  </div>
                  <div className="pr-table brdr-right-pr text-indent-cust">
                    <p>Package Name</p>
                  </div>
                  <div className="pr-table brdr-right-pr text-indent-cust">
                    <p>Hotel Name</p>
                  </div>
                  <div className="pr-table text-indent-cust">
                    <p>Action</p>
                  </div>
                </div>
                {hotels?.map((hotel) => (
                  <div key={hotel.id} className="pr-table-inner-parent pr-table-inner-parent-rev">
                    <div className="pr-table brdr-right-pr text-center">
                      <input type="checkbox" />
                    </div>
                    <div className="pr-table brdr-right-pr text-center align-items-center">
                      {
                        hotel.hotelImage && JSON.parse(hotel?.hotelImage).length > 0 &&
                        <img src={getImage(JSON.parse(hotel?.hotelImage)[0])} alt="" />
                        // <img src={getImage('1719361348594.jpg')} alt="" />
                      }
                    </div>
                    <div className="pr-table brdr-right-pr text-indent-cust">
                      <p>{hotel?.Package?.packageName}</p>
                    </div>
                    <div className="pr-table brdr-right-pr text-indent-cust">
                      <p>{hotel.hotelName}</p>
                    </div>
                    <div className="pr-table text-indent-cust">
                      <div className="dlt-edit">
                        <img
                          className="pr-table-del-img"
                          onClick={() => handleShow(hotel.id)}
                          src={PrIconDel}
                          alt=""
                        />
                        <Link to={`/EditHotel/${hotel.id}`}>
                          <img src={PrIconEdit} alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="DeleteModal" show={show} onHide={handleClose}>
        <div className="delete-modal-parent">
          <div className="modal-close-button text-end">
            <img onClick={handleClose} src={ModalCancelIcon} alt="" />
          </div>
          <div className="delete-modal-content-parent">
            <div className="dlt-modal-img">
              <img src={ModalDeleteIcon} alt="" />
            </div>
            <div className="dlte-modal-txt">
              <h3>You are about to delete this hotel</h3>
            </div>
            <div className="delete-modal-btn-parent">
              <button className="GoBack" onClick={handleClose}>
                Go Back
              </button>
              <button
                className="dlt-any"
                onClick={() => {
                  handleDelete(selectedHotelId);
                  handleClose();
                }}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default HotelDetails;
