import React from "react";
import { Link } from "react-router-dom";
import Bannervid from "../../assets/imgs/hajVid.mp4";
import Analyticsxl from "../../assets/imgs/anly-xl.png";
import Cartxl from "../../assets/imgs/cart-xl.png";
import MonthlySalesImg from "../../assets/imgs/monthly-sales.png";
import YearlySalesImg from "../../assets/imgs/yearly-sales.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import EyeIcon from "../../assets/imgs/eye-icon.png";

function Dashboard() {
  return (
    <>
      <div className="second-side-parent-dashboard">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header">
            <div className="dashboard-heading text-center">
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
}

export default Dashboard;
