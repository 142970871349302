import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Wapisi from "../../assets/imgs/ret-icn.png";
import LeftChevi from "../../assets/imgs/left-chev.png";
import EditGrey from "../../assets/imgs/edit-grey.png";
import PlusCirc from "../../assets/imgs/plus-circ.png";
import SideBar from "./SideBar";
import Swal from "sweetalert2";
import baseUrl from "../../utils/utils";
import Cross from "../../assets/imgs/cart-cancel.png";

function EditHotel() {
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [packagesData, setPackagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to manage loader

  const [formValues, setFormValues] = useState({
    packageName: "",
    hotelImage: [],
    id: "",
    packageId: "",
    hotelName: "",
    persons: "",
  });

  useEffect(() => {
    axios
      .get(`${baseUrl}/getAllPackages`)
      .then((response) => {
        setPackagesData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the package data!", error);
      });

    axios
      .get(`${baseUrl}/getHotel/${id}`)
      .then((response) => {
        const hotelData = response.data;
        setFormValues({
          packageName: hotelData.packageName,
          hotelImage: hotelData.hotelImage
            ? JSON.parse(hotelData.hotelImage)
            : [],
          id: id,
          packageId: hotelData.packageId,
          hotelName: hotelData.hotelName,
          persons: hotelData.persons,
        });
        if (hotelData.image) {
          setSelectedImage(hotelData.image);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the hotel data!", error);
      });
  }, [id]);

  const handleImageChange = async (e) => {
    setIsLoading(true);
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.map((file) => formData.append(`file`, file));
    const data = await axios.post(`${baseUrl}/uploadImage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const imgNames = data?.data?.map((file) => file.filename);

    setFormValues({
      ...formValues,
      hotelImage: [...formValues.hotelImage, ...imgNames],
    });

    setIsLoading(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "packageName") {
      const selectedPackage = packagesData.find(
        (pkg) => pkg.packageName === value
      );
      setFormValues({
        ...formValues,
        packageName: selectedPackage ? selectedPackage.packageName : "",
        packageId: selectedPackage ? selectedPackage.id : "",
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    axios
      .put(`${baseUrl}/updateHotel/${id}`, formValues)
      .then(() => {
        Swal.fire("Success!", "Hotel updated successfully!", "success");
      })
      .catch((error) => {
        console.error("There was an error updating the hotel!", error);
        Swal.fire(
          "Error!",
          `Failed to update hotel: ${error.message}`,
          "error"
        );
      });
  };

  console.log({ formValues });

  return (
    <>
      <SideBar />
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Edit Hotel</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                {/* <Link to="/Product">
                  <img src={Wapisi} alt="" />
                </Link> */}
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="left-chevi">
                <Link to="/Product">
                  <img src={LeftChevi} alt="" />
                </Link>
              </div>
              <div className="order-list-heading inner-left-head pr-add-heading d-flex align-items-center">
                <img className="invert-none" src={EditGrey} alt="" />
                <h4>Edit Hotels By Package</h4>
              </div>
              <div className="add-pr-row">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="col9inner-add-pr">
                      <div className="input-pr-parent-sup">
                        <div className="Section-AddPack">
                          <h5>Hotels</h5>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Package Name
                            </h4>
                          </div>
                          <div className="input-pr">
                            <select
                              name="packageName"
                              value={formValues.packageId}
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                {formValues.packageName
                                  ? formValues.packageName
                                  : "Select a package"}
                              </option>
                              {packagesData?.map((pkg) => (
                                <option key={pkg.id} value={pkg.packageName}>
                                  {pkg.packageName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-center justify-content-end">
                            <h4>
                              <span>*</span>Hotel Name
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="text"
                              name="hotelName"
                              value={formValues.hotelName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="container-for-img">
                      <div className="col-lg-6">
                        <div className="right-parent-sup">
                          <div className="right-parent">
                            <div className="image-box-parent">
                              <div className="image-box-heading">
                                <h3>Hotel Image ( Jpg/Png )</h3>
                              </div>
                              <div
                                className="image-box"
                                onClick={() =>
                                  document.getElementById("fileInput").click()
                                }
                              >
                                <input
                                  type="file"
                                  className="image-input"
                                  id="fileInput"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                  style={{ display: "none" }}
                                />
                                <p>
                                  <span>
                                    <img
                                      className="img-plus"
                                      src={PlusCirc}
                                      alt=""
                                    />
                                  </span>
                                  Upload Hotel Image
                                </p>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  gap: "15px",
                                  margin: "20px 0",
                                }}
                              >
                                {formValues?.hotelImage.map((img, ind) => (
                                  <div style={{ position: "relative" }}>
                                    <img
                                      onClick={() => {
                                        const arr = formValues.hotelImage;
                                        arr.splice(ind, 1);
                                        setFormValues({
                                          ...formValues,
                                          hotelImage: arr,
                                        });
                                      }}
                                      style={{
                                        position: "absolute",
                                        right: "-10px",
                                        cursor: "pointer",
                                        top: "-10px",
                                        background: "white",
                                        borderRadius: "15px",
                                      }}
                                      src={Cross}
                                    />
                                    <img
                                      style={{ maxWidth: "200px" }}
                                      src={`${baseUrl}/image/${img}`}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* <div className="prdt-upld-btn">
                            <Link to="#">Upload</Link>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="save-btn-add-pr d-flex justify-content-end">
                      <button disabled={isLoading} onClick={handleSubmit}>
                        {isLoading ? "Loading..." : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditHotel;
