import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import SideBar from "./SideBar";
import baseUrl from "../../utils/utils";

import Eyeicn from "../../assets/imgs/eye-icon.png";
import FilterIcon from "../../assets/imgs/filt.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import Pagi from "../../assets/imgs/pagi.png";
import PrIconDel from "../../assets/imgs/pr-del.png";
import PrIconEdit from "../../assets/imgs/pr-edit.png";
import ModalDeleteIcon from "../../assets/imgs/modal-delete.png";
import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";
import { Modal } from "bootstrap";

function PriceDetails() {
  const [prices, setPrices] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = () => {
    axios
      .get(`${baseUrl}/getAllPrices`)
      .then((response) => {
        setPrices(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the prices data!", error);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}/deletePrice/${id}`)
      .then(() => {
        alert("Price deleted successfully!");
        fetchPrices(); // Refresh the prices list after deletion
      })
      .catch((error) => {
        console.error("There was an error deleting the price!", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedPriceId(id);
    setShow(true);
  };

  return (
    <>
      <SideBar />
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Details</h2>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="order-list-heading inner-left-head d-flex align-items-center">
                <img src={BulletedIcon} alt="" />
                <h4>Prices</h4>
              </div>
              <div className="row pd-customer-row">
                <div className="col-lg-8">
                  <div className="grid-sales-parent sales-grid-parent">
                    <div className="inner-sale-grid">
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          Package ID
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          No. of persons
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          Price Per-person
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          Total Price
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "green" }}>
                          Action
                        </p>
                      </div>
                    </div>
                    {prices?.map((price, index) => {
                      return (
                        <div key={index} className="inner-sale-grid">
                          <div className="inner-grid-sales">
                            <p>{price?.Package?.packageName}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{price?.persons}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{price?.amountPerPerson}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{price?.persons * price?.amountPerPerson}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <div className="dlt-edit">
                              <img
                                className="pr-table-del-img"
                                onClick={() => handleShow(price?.id)}
                                src={PrIconDel}
                                alt=""
                              />
                              <Link to={`/EditPrice/${price?.id}`}>
                                <img src={PrIconEdit} alt="" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for delete confirmation */}
      <div
        className={`modal fade ${show ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Delete</h5>
              {/* <button type="button" className="close" onClick={handleClose}>
                <span>&times;</span>
              </button> */}
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this price?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>
                <img src={ModalCancelIcon} alt="Cancel" width={"50px"} /> Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleDelete(selectedPriceId);
                  handleClose();
                }}
              >
                <img src={ModalDeleteIcon} alt="Delete" width={"50px"}  /> Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceDetails;
