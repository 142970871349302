import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./assets/css/admin.css";
import "./assets/css/adminresponsive.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AdminProduct from "./Admin/AdminProduct";
import AdminProductAdd from "./Admin/AdminProductAdd";
import AdminCustomer from "./Admin/AdminCustomer";
import AdminCustomerEdit from "./Admin/AdminCustomerEdit";
import AdminManageReviews from "./Admin/AdminManageReviews";
import AdminReviewsEdit from "./Admin/AdminReviewsEdit";
import AdminLogin from "./Admin/AdminLogin";
import AdminSales from "./Admin/AdminSales";
import AdminSalesView from "./Admin/AdminSalesView";
import EditProduct from "./Admin/components/EditPackage";
import AdminDashboard from "./Admin/AdminDashboard";
import AddPrice from "./Admin/components/AddPrice";
import AddHotels from "./Admin/components/AddHotels";
import PriceDetails from "./Admin/components/PriceDetails";
import HotelDetails from "./Admin/components/HotelDetails";
import EditPackage from "./Admin/components/EditPackage";
import EditPrice from "./Admin/components/EditPrice";
import EditHotel from "./Admin/components/EditHotel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <RotesABC />
    </BrowserRouter>
  );
}

const RotesABC = () => {
  const isLogin = localStorage.getItem("isLogin");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate("/");
    }
  }, [isLogin]);

  return (
    <Routes>
      <Route path="/" element={<AdminLogin />} />
      <Route path="/AdminDashboard" element={<AdminDashboard />} />
      <Route path="/Product" element={<AdminProduct />} />
      <Route path="/ProductAdd" element={<AdminProductAdd />} />
      <Route path="/Customer" element={<AdminCustomer />} />
      <Route path="/addPrice" element={<AddPrice />} />
      <Route path="/addHotels" element={<AddHotels />} />
      <Route path="/CustomerEdit" element={<AdminCustomerEdit />} />
      <Route path="/Reviews" element={<AdminManageReviews />} />
      <Route path="/ReviewsEdit" element={<AdminReviewsEdit />} />
      <Route path="/EditProduct" element={<EditProduct />} />
      <Route path="/EditPackage/:id" element={<EditPackage />} />
      <Route path="/EditPrice/:id" element={<EditPrice />} />
      <Route path="/EditHotel/:id" element={<EditHotel />} />
      <Route path="/Sales" element={<AdminSales />} />
      <Route path="/SalesView/:id" element={<AdminSalesView />} /> */
      <Route path="/priceDetail" element={<PriceDetails />} /> */
      <Route path="/hotelDetail" element={<HotelDetails />} /> */
    </Routes>
  );
};

export default App;
