import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Eyeicn from "../../assets/imgs/eye-icon.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import PrIconDel from "../../assets/imgs/pr-del.png";
import PrIconEdit from "../../assets/imgs/pr-edit.png";
import Swal from "sweetalert2";
import baseUrl from "../../utils/utils";

function Sales() {
  const [packagesData, setPackagesData] = useState([]);
  const [id, setId] = useState();

  // Function to fetch all packages
  const fetchPackages = () => {
    axios
      .get(`${baseUrl}/getAllPackages`)
      .then((response) => {
        setPackagesData(response.data);
        console.log(response.data, "all packages here");
      })
      .catch((error) => {
        console.error("There was an error fetching the package data!", error);
      });
  };

  // Fetch packages on component mount
  useEffect(() => {
    fetchPackages();
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}/deletePackage/${id}`)
      .then((response) => {
        console.log(response.data);
        Swal.fire("Success!", "Successfully Deleted", "success");
        fetchPackages(); // Refetch packages after deletion
      })
      .catch((error) => {
        console.error("Error deleting package:", error);
        Swal.fire("Error!", `Failed to delete: ${error.message}`, "error");
      });
  };

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Details</h2>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="order-list-heading inner-left-head d-flex align-items-center">
                <img src={BulletedIcon} alt="" />
                <h4>Packages</h4>
              </div>
              <div className="row pd-customer-row">
                <div className="col-lg-8">
                  <div className="grid-sales-parent sales-grid-parent">
                    <div className="inner-sale-grid">
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          Package Name
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          Start Destination
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          Final Destination
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          Price per person
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "black" }}>
                          No. of Days
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p style={{ fontWeight: "600", color: "green" }}>
                          Action
                        </p>
                      </div>
                    </div>
                    {packagesData?.map((pkg, index) => {
                      // Find the price object where persons is 1
                      const filteredPrice = pkg?.Prices?.find(
                        (price) => price.persons === 1
                      );

                      // Determine the amount to display
                      const amountToDisplay = filteredPrice
                        ? filteredPrice.amountPerPerson
                        : "AFP";

                      return (
                        <div key={index} className="inner-sale-grid">
                          <div className="inner-grid-sales">
                            <p>{pkg?.packageName}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{pkg?.from}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{pkg?.secondDestination}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{amountToDisplay}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{pkg?.days}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <div className="dlt-edit">
                              <Link to={`/SalesView/${pkg.id}`}>
                                <img src={Eyeicn} alt="" />
                              </Link>
                              <div onClick={() => handleDelete(pkg.id)}>
                                <img
                                  className="pr-table-del-img"
                                  src={PrIconDel}
                                  alt=""
                                />
                              </div>
                              <Link to={`/EditPackage/${pkg.id}`}>
                                <img src={PrIconEdit} alt="" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="d-flex justify-content-between">
                    <div className="pagi">
                      <img src={Pagi} alt="" />
                    </div>
                    <div className="txt-right-pagi">
                      <p>Showing 1 to 10 of 90 (9 Pages)</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal className="DeleteModal" show={show} onHide={handleClose}>
        <div className="delete-modal-parent">
          <div className="modal-close-button text-end">
            <img onClick={handleClose} src={ModalCancelIcon} alt="" />
          </div>
          <div className="delete-modal-content-parent">
            <div className="dlt-modal-img">
              <img src={ModalDeleteIcon} alt="" />
            </div>
            <div className="dlte-modal-txt">
              <h3>You are about to delete this package</h3>
            </div>
            <div className="delete-modal-btn-parent">
              <button className="GoBack" onClick={handleClose}>
                Go Back
              </button>
              <button className="dlt-any" onClick={handleDelete(id)}>
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

export default Sales;
