import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Wapisi from "../../assets/imgs/ret-icn.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";
import axios from "axios";
import baseUrl from "../../utils/utils";

// const packagesData = [
//   {
//     id: 1,
//     packageInfo: {
//       packageName: "Summer Adventure",
//       departureDate: "2024-06-15",
//       returnDate: "2024-06-25",
//       numberOfDays: 10,
//       numberOfNights: 9,
//     },
//     flightDetails: {
//       flyingFrom: "New York",
//       airline: "Delta",
//       baggageAllowance: "2 bags, 50 lbs each",
//       stopoverIn: "Atlanta",
//       departureTime: "10:00 AM",
//       arrivalTime: "1:00 PM",
//       arrivalDestination: "Los Angeles",
//       secondDestination: "San Francisco",
//       route: "JFK -> ATL -> LAX -> SFO",
//     },
//     hotelInformation: {
//       hotel: "Hilton Los Angeles",
//     },
//     prices: {
//       adultPrice: 1200,
//       childPrice: 900,
//       babyPrice: 300,
//     },
//     totalPackagePrice: {
//       packagePrice: 3300,
//     },
//     description:
//       "An exciting summer adventure from New York to Los Angeles, with stops in Atlanta and San Francisco. Includes flights, hotel stay, and more.",
//   },
//   {
//     id: 2,
//     packageInfo: {
//       packageName: "Winter Getaway",
//       departureDate: "2024-12-01",
//       returnDate: "2024-12-10",
//       numberOfDays: 10,
//       numberOfNights: 9,
//     },
//     flightDetails: {
//       flyingFrom: "Chicago",
//       airline: "American Airlines",
//       baggageAllowance: "2 bags, 50 lbs each",
//       stopoverIn: "Dallas",
//       departureTime: "9:00 AM",
//       arrivalTime: "12:00 PM",
//       arrivalDestination: "Miami",
//       secondDestination: "Orlando",
//       route: "ORD -> DFW -> MIA -> MCO",
//     },
//     hotelInformation: {
//       hotel: "Marriott Miami",
//     },
//     prices: {
//       adultPrice: 1100,
//       childPrice: 850,
//       babyPrice: 250,
//     },
//     totalPackagePrice: {
//       packagePrice: 3200,
//     },
//     description:
//       "A cozy winter getaway from Chicago to Miami, with stops in Dallas and Orlando. Includes flights, hotel stay, and more.",
//   },
//   // Add more package objects here
// ];




function SalesView() {
  const [packagesData, setPackagesData] = useState([]);

  const { id } = useParams();

  // Function to fetch all packages
  const fetchPackages = () => {
    axios
      .get(`${baseUrl}/getPackage/${id}`)
      .then((response) => {
        setPackagesData(response.data);
        console.log(response.data, "all packages here");
      })
      .catch((error) => {
        console.error("There was an error fetching the package data!", error);
      });
  };

  // Fetch packages on component mount
  useEffect(() => {
    fetchPackages();
  }, []);



  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showtwo, setShowtwo] = useState(false);

  const handleClosetwo = () => setShowtwo(false);
  const handleShowtwo = () => setShowtwo(true);

  // const packagesData = packagesData?.find((pkg) => pkg.id === parseInt(id));
  console.log(packagesData, "packagesDatapackagesDatapackagesData");
  if (packagesData == []) {
    return <p>Package not found</p>;
  }

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Package Detail</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                <Link to="/Sales">
                  <img src={Wapisi} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="sales-view-content-parent">
                <div className="sal-view-ordheading">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <h4>Package ID (#{id})</h4>
                </div>
                <div className="inv-row-parent">
                  <div className="row">
                    <div className="col-lg-4">
                      <div
                        className="env-box position-relative"
                        onClick={handleShow}
                      >
                        <h5>Package Information</h5>
                        <p>General Information Related to Package</p>
                        <div className="setting-icn-box" onClick={handleShow}>
                          {/* <FontAwesomeIcon icon={faGear} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box">
                        <h5>Package Name</h5>
                        <p>{packagesData?.packageName}</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box">
                        <h5>Total Number of Days</h5>
                        <p>{packagesData?.days}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="billing-row-parent">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="billing-box position-relative">
                        <h5>Flight Details</h5>
                        <p>
                          Flying From: ------{" "}
                          {packagesData?.from}
                        </p>
                        <p>
                          Airline Name: ------{" "}
                          {packagesData?.airLine}
                        </p>
                        <p>
                          Baggage allowance: ----------------{" "}
                          {packagesData?.baggageAllowance}
                        </p>
                        <p>
                          Stopover in: ---------{" "}
                          {packagesData?.stopoverIn}
                        </p>
                        <p>
                          Departure time: ---------
                          {packagesData?.departureTime}
                        </p>
                        <p>
                          Arrival time: -----------{" "}
                          {packagesData?.arrivalTime}
                        </p>
                        <p>
                          Arrival destination: -----------
                          {packagesData?.arrivalDestination}
                        </p>
                        <p>
                          Second destination: -----------
                          {packagesData?.secondDestination}
                        </p>
                        <p>
                          Route: -----------
                          {packagesData?.route}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="billing-box position-relative">
                        <h5>General Details</h5>
                        <p>
                          {" "}
                          Package Name: {packagesData?.packageName}
                        </p>
                        <p>
                          {" "}
                          Departure date: {packagesData?.departureDate}
                        </p>
                        <p> Return date: {packagesData?.returnDate}</p>
                        <p>
                          {" "}
                          Number of days: {packagesData?.numberOfDays}
                        </p>
                        <p>
                          {" "}
                          Number of nights: {packagesData?.numberOfNights}
                        </p>

                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="billing-box position-relative">
                        <h5>Hotle Details</h5>
                        <p>
                          hotle Name: ------
                          {packagesData?.hotel}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="billing-box position-relative">
                        <h5>Price Details</h5>
                        <p>
                          Package Price:{" "}

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="method-payment-row-prnt">
                  {/* <div className="row">
                                        <div className="col-lg-12">
                                            <div className="py-mthd-box">
                                                <h5>Payment Method</h5>
                                                <p>Credit Card</p>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal className="DeleteModal" show={show} onHide={handleClose}>
        <div className="billing-row-parent">
          <div className="row">
            <div className="col-lg-12">
              <div className="billing-box position-relative">
                <h5>General Details</h5>
                <p>
                  {" "}
                  Departure date: {packagesData.packageInfo.departureDate}
                </p>
                <p> Return date: {packagesData.packageInfo.returnDate}</p>
                <p>
                  {" "}
                  Number of days: {packagesData.packageInfo.numberOfDays}
                </p>
                <p>
                  {" "}
                  Number of nights: {packagesData.packageInfo.numberOfNights}
                </p>
                <p>
                  Package Price:{" "}
                  {packagesData.totalPackagePrice.packagePrice}
                </p>
              </div>
            </div>
          </div>
          <br></br>
        </div>
      </Modal> */}
      <Modal
        className="DeleteModal INV-modal"
        show={showtwo}
        onHide={handleClosetwo}
      >
        <div className="billing-modal-parent position-relative">
          <div className="billing-modal-inner-parent">
            <div className="modal-close-button text-end">
              <img onClick={handleClosetwo} src={ModalCancelIcon} alt="" />
            </div>
            <div className="billing-heading text-center">
              <h3>INV-2022-000</h3>
            </div>
            <hr />
            <div className="inv-modal-input-parent">
              <div className="grid-inpt-modal-parent">
                <div className="grid-inpt-modal">
                  <label htmlFor="inv-mdl-one">Order #</label>
                  <input type="number" placeholder="1446" id="inv-mdl-one" />
                </div>
                <div className="grid-inpt-modal">
                  <label htmlFor="inv-mdl-two">Date Added</label>
                  <input
                    type="text"
                    placeholder="25/07/2023"
                    id="inv-mdl-two"
                  />
                </div>
              </div>
              <div className="grid-inpt-modal-parent">
                <div className="grid-inpt-modal">
                  <label htmlFor="inv-mdl-three">Name</label>
                  <input
                    type="text"
                    placeholder="Mark Allen"
                    id="inv-mdl-three"
                  />
                </div>
                <div className="grid-inpt-modal">
                  <label htmlFor="inv-mdl-four">Email</label>
                  <input
                    type="email"
                    placeholder="mark_allen@gmail.com"
                    id="inv-mdl-four"
                  />
                </div>
                <div className="grid-inpt-modal">
                  <label htmlFor="inv-mdl-five">Contact #</label>
                  <input
                    type="number"
                    placeholder="1234 567 890"
                    id="inv-mdl-five"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-six">Billing Address</label>
                    <textarea
                      id="inv-mdl-six"
                      placeholder="Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod 
tempor incididunt magna aliqua."
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-seven">Payment Method</label>
                    <input
                      type="text"
                      id="inv-mdl-six"
                      placeholder="Credit Card"
                    />
                  </div>
                </div>
              </div>
              <div className="table-modal-parent">
                <div className="table-modal-inner-parent">
                  <div className="table-modal-inner text-center mdl-right-border">
                    <p>Product</p>
                  </div>
                  <div className="table-modal-inner text-center mdl-right-border">
                    <p>Quantity</p>
                  </div>
                  <div className="table-modal-inner text-center mdl-right-border">
                    <p>Unit Price</p>
                  </div>
                  <div className="table-modal-inner text-start pd-left-mdl-ttl">
                    <p>Total</p>
                  </div>
                </div>
                <div className="table-modal-inner-parent mdl-top-border">
                  <div className="table-modal-inner text-center mdl-right-border">
                    <p>Product Name</p>
                  </div>
                  <div className="table-modal-inner text-center mdl-right-border">
                    <p>2</p>
                  </div>
                  <div className="table-modal-inner text-center mdl-right-border">
                    <p>$122.00</p>
                  </div>
                  <div className="table-modal-inner text-start pd-left-mdl-ttl">
                    <p>$244.00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SalesView;
